<template>
  <land-contact-way
    :dark="wdDark"
    :back-color="wdColor"
  />
</template>

<script>
  export default {
    name: 'SectionKeepInTouch',
    data () {
      return {
      }
    },
    computed: {
      wdDark () {
        return this.$route.meta.dark === true
      },
      wdColor () {
        if (this.$route.meta.dark === true) {
          return 'blue-grey darken-4'
        } else {
          return 'transparent'
        }
      }
    },
  }
</script>
